import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";
import {
  Box,
  Paper,
  Stack,
  Typography,
  DatePicker,
} from "../../ui/core";

import { useQuery } from "../../hooks";
import {
  TripDisplayStatus,
  TripStatus,
} from "../../models/Trips";

import { getDailyTrips } from "../../redux/Trips/actions";
import { getCities } from "../../redux/Cities/actions";
import { getTags } from "../../redux/Tags/actions";

import TripDetailsDialog from "../../components/widgets/Trips/TripDetailsDialog";
import TripsTable from "../../components/widgets/Trips/TripsTable";
import { TripDisplayStatusIcon } from "../../components/widgets/Trips/TripDisplayStatus";
import CitiesSelector from "../../components/widgets/Trips/CitiesSelector";

const GROUP_COLUMN = {
  field: "displayStatus",
  groupBy: true,
  groupExpanded: true,
  getGroupCount: ({ value, rows }) => {
    return rows.filter(
      (r) => r.displayStatus === value && !r.isPrevious && !r.isNext
    ).length;
  },
  getGroupOrder: ({ value }) => {
    return (
      [
        TripDisplayStatus.needs_atention,
        TripDisplayStatus.finding_driver,
        TripDisplayStatus.in_progress,
        TripDisplayStatus.finalizing_details,
        TripDisplayStatus.completed,
        TripDisplayStatus.cancelled,
      ].indexOf(value) + 1
    );
  },
  renderGroupHeader: ({ value, count }) => {
    return (
      <Stack direction="row" spacing="10px">
        <TripDisplayStatusIcon status={value} />
        <Typography>{value}</Typography>
        <Typography color="text.secondary">({count})</Typography>
      </Stack>
    );
  },
}

const updateURLParams = ({ date, citiesIds, tripId, fullScreen }, history) => {
  const params = new URLSearchParams();
  params.append("date", date);
  citiesIds.length && params.append("citiesIds", citiesIds.join(","));
  if (tripId) {
    params.append("tripId", tripId);
    params.append("fullScreen", !!fullScreen);
  }
  history.push({ search: params.toString() });
};

const TripsDaily = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  let { date, tripId, citiesIds, fullScreen } = useQuery();
  date = date || new Date().toLocaleDateString('en-CA'); //default to today
  citiesIds = (citiesIds || '').split(',').filter((id) => !!id);

  const {
    data,
    date: loadedDate,
    loading: loadingData,
  } = useSelector((state) => state.trips.dailyTrips);

  const citiesOptions = useMemo(() => {
    const tripsCities = data.reduce((cities, t) => [...cities, t.originCity, t.destinationCity], [])
    return uniqBy(tripsCities, c => c.id);
  }, [data]);

  const filteredData = citiesIds.length > 0 ?
    data.filter((t) => citiesIds.includes(t.originCity.id) || citiesIds.includes(t.destinationCity.id)) :
    data;

  const sortedData = filteredData
    .sort(
      (d1, d2) =>
        Date.parse(d1.departureTime) - Date.parse(d2.departureTime)
    )

  useEffect(() => {
    getCities()(dispatch);
    getTags()(dispatch);
  }, []);

  useEffect(() => {
    if (loadedDate !== date) {
      getDailyTrips(date)(dispatch);
    }
  }, [date]);

  const onDateChange = (e) => updateURLParams({ date: e.target.value, citiesIds, tripId, fullScreen }, history);

  const onRowClick = ({ row }) => updateURLParams({ date, citiesIds, tripId: row.id, fullScreen: true }, history);

  const onTripDetailsClose = () => updateURLParams({ date, citiesIds }, history);

  const onTripDetailsFullscreen = (fullScreen) => updateURLParams({ date, citiesIds, tripId, fullScreen }, history);

  const onCitiesFilterChange = (value) => updateURLParams({ date, citiesIds: value.map((c) => c.id), tripId, fullScreen }, history);

  const calculateMetrics = (trips) => {
    const metrics = {
      netRevnuePerSeat: 0,
      grossRevenue: 0,
      driverPayout: 0,
    };
    trips = trips.filter((trip) => trip.status !== TripStatus.cancelled);

    let totalRevenue = 0;
    let totalPayout = 0;
    let totalSeats = 0;

    trips.forEach((trip) => {
      const rides = trip.rides;
      rides.forEach((ride) => {
        totalRevenue += ride.fare.grossAmount;
        totalSeats += ride.seatCount;
      });
      totalPayout += trip.payout;
    });

    metrics.netRevnuePerSeat = (totalRevenue - totalPayout) / totalSeats;
    metrics.grossRevenue = totalRevenue;
    metrics.driverPayout = totalPayout;
    return metrics;
  };

  // const metrics = calculateMetrics(data);

  return (
    <Box>
      <Paper elevation={0}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ padding: "32px" }}
        >
          <Typography
            sx={{ fontWeight: 700, fontSize: "32px", lineHeight: "40px", flex: 1 }}
          >
            All trips
          </Typography>
          <CitiesSelector
            options={citiesOptions}
            value={citiesOptions.filter((c) => citiesIds.includes(c.id))}
            onChange={onCitiesFilterChange}
          />
          <DatePicker id="trips_date" value={date} onChange={onDateChange} />
        </Stack>
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ padding: "32px" }}
        >
          <Typography variant="h6">
            Metrics: ${metrics.netRevnuePerSeat.toFixed(2)}
          </Typography>
        </Stack> */}

        <div style={{ width: "100%", height: "calc(100vh - 262px)" }}>
          <TripsTable
            data={sortedData}
            loading={loadingData}
            onRowClick={onRowClick}
            getColumn={(c) => {
              if (c.field === GROUP_COLUMN.field) {
                return { ...c, ...GROUP_COLUMN };
              }
              return c;
            }}
          />
        </div>
      </Paper>
      {
        <TripDetailsDialog
          trip={data.find((t) => t.id === tripId)}
          fullScreen={fullScreen === "true"}
          returnTrip={
            tripId ? data.find((t) => t.previousTrip === tripId) : null
          }
          onClose={onTripDetailsClose}
          onFullscreen={onTripDetailsFullscreen}
        />
      }
    </Box>
  );
};

export default TripsDaily;
